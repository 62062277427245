import { useRouter } from 'next/router'
import { en, fr } from '@/utils/i18n/locale'
import _ from 'lodash'
export const useTranslation = () => {
  const router = useRouter()
  const { locale } = router

  const lang = locale === 'fr' ? fr : en

  const t = (key: string, forceLang?: 'fr' | 'en') => {
    const keys = _.split(key, '.')
    return (
      keys.reduce(
        (previousKey, currentKey) => {
          return previousKey ? previousKey[currentKey] : previousKey
        },
        forceLang ? (forceLang === 'fr' ? fr : en) : lang
      ) || key
    )
  }
  return t
}

export const useLocale = () => {
  const router = useRouter()
  const { locale } = router

  const lang = locale === 'fr' ? 'fr' : 'en'

  return lang
}
