export const en = {
  helloworld: 'Hello World',
  getstarted: 'Get Started',
  common: {
    time: 'Time',
    actions: 'Actions',
    add: 'Add',
    addNewLink: 'Add new link',
    admin: 'Admin',
    adminSupport: 'Admin Support',
    allUsers: 'All Users',
    attachFile: 'Attach File',
    yes: 'Yes',
    no: 'No',
    all: 'All',
    cancel: 'Cancel',
    daysInWeek: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday'
    },
    documents: 'Documents',
    address: 'Address',
    addressAddedSuccessfully: 'Address added successfully',
    addressUpdatedSuccessfully: 'Address updated successfully',
    approve: 'Approve',
    approved: 'Approved',
    easternDaylightTime: 'Eastern Daylight Time',
    archive: 'Archive',
    archived: 'Archived',
    areYouSureYouWantToArchiveThis: 'Are you sure you want to archive this?',
    areYouSureYouWantToDeleteThis: 'Are you sure you want to delete this?',
    areYouSureYouWantToLogout: 'Are you sure you want to log out?',
    areYouSureYouWantToDeleteIt: 'Are you sure you want to delete it?',
    back: 'Back',
    bannerLogo: 'Banner',
    city: 'City',
    chronic: 'Chronic',
    close: 'Close',
    clientAddedSuccessfully: 'Client added successfully',
    clientDeletedSuccessfully: 'Client deleted successfully',
    clientUpdatedSuccessfully: 'Client updated successfully',
    Clients: 'Clients',
    default: 'default',
    selectAll: 'Select All',
    comment: 'Comment',
    dateDispensed: 'Date Dispensed',
    dateWritten: 'Date Written',
    drugName: 'Drug Name',
    deceased: 'Deceased',
    delete: 'Delete',
    delivery: 'Delivery',
    day: 'day',
    days: 'days',
    defaultAddress: 'Default Address',
    description: 'Description',
    drugCreated: 'Drug created successfully',
    doctorCreated: 'Prescriber created successfully',
    doctorEdited: 'Prescriber updated successfully',
    drugEdited: 'Drug updated successfully',
    docs: 'Docs',
    Drugs: 'Drugs',
    Doctors: 'Doctors',
    edit: 'Edit',
    Empego: 'Empego',
    empegoLinks: 'Empego Links',
    english: 'English',
    false: 'False',
    female: 'Female',
    Followups: 'Follow-up',
    french: 'French',
    fulfilled: 'Fulfilled',
    help: 'Help',
    id: 'ID',
    insuranceAddedSuccessfully: 'Insurance added successfully',
    insuranceAlreadyApproved: 'Insurance already approved',
    insuranceProof: 'Insurance Proof',
    insuranceUpdatedSuccessfully: 'Insurance updated successfully',
    intolerances: 'Intolerances',
    lastSeen: 'Last seen',
    link: 'Link',
    linkedAccounts: 'Linked Accounts',
    linkUserTo: 'Link User to',
    linkUserToEstablishmentSuccessfully: 'User linked to establishment successfully',
    loading: 'Loading ...',
    locale: 'en',
    logOut: 'Log out',
    mail: 'Mail',
    male: 'Male',
    medicalForm: 'Medical Form',
    medicalInfo: 'Medical Info',
    morning: 'morning',
    Messages: 'Messages',
    name: 'Name',
    neverSignedIn: 'never signed in',
    notAvailable: 'Not Available',
    noFound: 'No Found',
    none: 'None',
    noOptions: 'No Options',
    notVerified: 'Not Verified',
    notServed: 'Not Served',
    noResultFound: 'No result found',
    noUserLinked: 'No User Linked',
    oAuthIdOfUserAccountToLink: 'oAuthId of user account to link',
    ok: 'Ok',
    optional: 'Optional',
    orderApprovedSuccessfully: 'Order approved successfully',
    orderBy: 'Order by',
    orderChargedSuccessfully: 'Order charged successfully',
    orderUpdatedSuccessfully: 'Order updated successfully',
    orderDeletedSuccessfully: 'Order deleted successfully',
    Orders: 'Orders',
    other: 'Other',
    paid: 'Paid',
    establishmentSwitchedTo: 'Establishment switched to',
    photoAlreadyVerified: 'Photo already verified',
    photoIdNotProvided: 'Photo ID not Provided',
    Establishments: 'Establishments',
    picture: 'Picture',
    pharmacist: 'Pharmacist',
    establishmentPhoneNumber: 'Establishment Phone Number',
    prescriptionCreatedSuccessfully: 'Prescription created successfully',
    prescriptionUpdatedSuccessfully: 'Prescription updated successfully',
    predefinedMessages: 'Pre-defined messages',
    price: 'Price',
    postalCode: 'Postal Code',
    quantity: 'Quantity',
    reactivate: 'Reactivate',
    after: 'After',
    repeatEvery: 'Repeat every',
    week: 'Week',
    month: 'Month',
    daily: 'Days',
    ends: 'Ends',
    on: 'On',
    occurrences: 'Occurrences',
    reject: 'Reject',
    rxRenewedSuccessfully: 'Rx renewed successfully',
    save: 'Save',
    scheduleSend: 'Schedule send',
    manageReccurence: 'Manage Reccurence',
    scheduleButton: 'Schedule',
    search: 'Search',
    searchAFile: 'Search a file',
    select: 'Select',
    selectOption: 'Select option',
    send: 'Send',
    selectOptions: 'Select options',
    shipped: 'Shipped',
    status: 'Status',
    superAdmin: 'Super Admin',
    'super admin': 'Super Admin',
    switchLanguage: 'Mettre en français',
    taskCreatedSuccessfully: 'Task created successfully',
    taskUpdatedSuccessfully: 'Task updated successfully',
    taskCompletedSuccessfully: 'Task completed successfully',
    taskCategoryCreated: 'Task category created successfully',
    taskCategoryDeleted: 'Task category deleted successfully',
    taskCategoryUpdated: 'Task category updated successfully',
    task: 'Task',
    Tasks: 'Tasks',
    taskCategories: 'Task Categories',
    true: 'True',
    type: 'Type',
    unauthorizedAccess: 'Unauthorized Access',
    unapproved: 'Unapproved',
    unlink: 'Unlink',
    unlinkUserFromEstablishmentSuccessfully: 'User unlinked from establishment successfully',
    Users: 'Users',
    userId: 'User ID',
    userIdCopied: 'User Id Copied',
    userLinkedSuccessfully: 'User linked successfully',
    userUpdatedSuccessfully: 'User updated successfully',
    userUnlinkedSuccessfully: 'User unlinked successfully',
    verbalScript: 'Verbal Script',
    verified: 'Verified',
    verifyPhotoId: 'Verify Photo ID',
    writtenScript: 'Written Script',
    Agenda: 'Agenda',
    Shipping: 'Shipping',
    drugAdded: 'Drug added successfully',
    userAlreadyExistInCurrentEstablishment: 'User already exist in current establishment',
    failToCreateANewUser: 'Unable to create new user at this moment',
    userCreated: 'User created successfully',
    userLinked: 'User linked successfully to your pharmacy',
    failDeleteInsurance: 'Failed to delete insurance',
    failDeleteScheduledMessage: 'Failed to delete scheduled message',
    pathologyNameEn: 'Pathology name (english)',
    pathologyNameFr: 'Pathology name (french)',
    requiredPathologyNameEn: 'Pathology name is required (English)',
    requiredPathologyNameFr: 'Pathology name is required (French)',
    requiredPreCodeLink: 'Pre-code link is required',
    requiredPostCodeLink: 'Post-code link is required'
  },
  clients: {
    addClient: 'Add a client',
    addAUser: 'Add a new user',
    addEstablishment: 'Add an establishment',
    bannerName: 'Banner name',
    businessHours: 'Business hours',
    manageUsers: 'Manage users',
    modifyClient: 'Modify Establishment',
    modify: 'Modify',
    delete: 'Delete',
    zipCode: 'ZIP code',
    phoneNumber: 'Phone number',
    name: 'Name',
    postalCode: 'ZIP code',
    phone: 'Phone number'
  },
  doctors: {
    license: 'License',
    note: 'Note',
    streetAddress: 'Street Address',
    firstName: 'First Name',
    lastName: 'Last Name',
    profession: 'Profession',
    phoneNumber: 'Phone Number',
    faxNumber: 'Fax Number',
    searchPrescribers: 'Search Prescribers',
    createPrescriber: 'Create Prescriber',
    editPrescriber: 'Edit Prescriber'
  },
  documents: {
    attach: 'Attach',
    browseFiles: 'Drag files here or click to browse',
    documentsAddedSuccessfully: 'Document added successfully',
    documentRemovedSuccessfully: 'Document removed successfully',
    insuranceProof: 'Insurance Proofs',
    medicalForm: 'Medical Forms',
    addFileTo: 'Add file to',
    fileAddedTo: 'File successfully added to',
    fileName: 'File name',
    medicalInformation: 'Medical Informations',
    noDocumentFound: 'No document found',
    picture: 'Pictures',
    prescription: 'Prescriptions',
    selectACategoryForUpload: 'Select a category for upload',
    maxSize: 'Max. file size',
    uploading: 'Uploading',
    fileSizeExceeds: 'File size exceeds',
    upload: 'Upload',
    uploadFromDevice: 'Upload from device',
    uploadFromDocuments: 'Upload from documents',
    selectMultiple: 'Select multiple',
    noDocuments: 'No documents',
    types: {
      photo: 'Photo',
      prescription: 'Prescription',
      'medical form': 'Medical Form',
      'proof of insurance': 'Proof of Insurance',
      'medication sheet': 'Medication Sheet',
      'medication information sheet': 'Medication Information Sheet',
      'user manual': 'User Manual',
      other: 'Other'
    }
  },
  drugs: {
    drugId: 'Drug Id',
    drugIdentificationNumber: 'DIN/NPN',
    name: 'Name',
    genericInfo: 'Gen Info',
    legalStatusQc: 'QC Legal Status',
    form: 'Form',
    strength: 'Strength',
    createDrug: 'Create Drug',
    searchDrugs: 'Search Drugs',
    editDrug: 'Edit Drug',
    nameEnCapitalized: 'Drug name in english (required)',
    nameFrCapitalized: 'Drug name in French',
    strengthEnLowercase: 'Strength in English (required)',
    strengthFrLowercase: 'Strength in French',
    formEnLowercase: 'Form in English',
    formFrLowercase: 'Form in French',
    dinOrNpn: 'DIN or NPN (check to ensure not already in the database)',
    dinOrNpnText: 'DIN/NPN',
    defaultPosology: 'Default Posology',
    requiredDrugName: 'Drug name is required',
    requiredStrength: 'Strength is required',
    requiredDinOrNpn: 'DIN/NPN is required',
    requiredFormInEnglish: 'Form is required',
    requiredFormInFrench: 'Form is required',
    requiredDefaultPosology: 'Default posology is required',
    videoUrl: 'Video Link'
  },
  editUser: {
    firstName: 'First Name',
    lastName: 'Last Name',
    ramqNumber: 'RAMQ Number',
    logipharmId: 'Logipharm ID',
    paysafeId: 'Paysafe ID',
    email: 'Email',
    website: 'Website',
    birthday: 'Birthday',
    phoneNumber: 'Phone number',
    gender: 'Gender',
    language: 'Language',
    domedicPatientId: 'Domedic Id',
    addEstablishments: 'Add Establishments',
    addPharmacies: 'Add Pharmacies',
    role: 'Role',
    pinConversation: 'Pin conversation'
  },
  empegoLink: {
    empegoLinkAdded: 'Empego link added successfully',
    empegoLinkUpdated: 'Empego link updated successfully',
    empegoLinkDeleted: 'Empego link deleted successfully',
    fullLink: 'Full Link',
    preCodeLink: 'Static part of the link',
    postCodeLink: 'Dynamic part of the link',
    defaultLink: 'Default Link'
  },
  insurance: {
    addInsurance: 'Add Insurance',
    areYouSureYouWantTo: 'Are you sure you want to',
    areYouSureYouWantToDeleteThis: 'Are you sure you want to delete this?',
    editInsurance: 'Edit Insurance',
    insuranceName: 'Insurance Name',
    fields: 'Fields',
    priority: 'Priority',
    photos: 'Photos',
    thisInterogation: 'this?',
    verified: 'Verified'
  },
  medicalHistory: {
    allergy: 'allergy',
    allergiesFound: 'allergies found',
    allergyFound: 'allergy found',
    areYouSureYouWantToDelete: 'Are you sure you want to delete?',
    intolerance: 'intolerance',
    intoleranceFound: 'intolerance found',
    intolerancesFound: 'intolerances found',
    pathology: 'pathology',
    pathologiesFound: 'pathologies found',
    pathologyFound: 'pathology found',
    no: 'No',
    notePart1a: 'Note: Add a',
    notePart1an: 'Note: Add an',
    notePart2: "description in user's language to ensure it displays properly in-app"
  },
  message: {
    afterTwoDays: 'After 2 days',
    afterSevenDays: 'After 7 days',
    afterThirtyDays: 'After 30 days',
    enterMessage: 'Enter Message',
    message: 'message',
    openProfile: 'Open Profile',
    searchMessage: 'Search Message',
    searchUser: 'Search User',
    sendAsAdmin: 'Send as Admin',
    sendAsBot: 'Send as Bot',
    tomorrowMorning: 'Tomorrow morning',
    tomorrowAfternoon: 'Tomorrow afternoon',
    tomorrowEvening: 'Tomorrow evening',
    pickDateAndTime: 'Pick date and time',
    customRecurrence: 'Custom recurrence',
    scheduledSuccessfully: 'Message scheduled successfully',
    editScheduledSuccessfully: 'Message updated successfully',
    editScheduledMessage: 'Edit scheduled message',
    messageText: 'Message Text',
    commonMessages: 'Common Messages',
    scheduledPosts: 'Scheduled Posts',
    recipientName: 'Recipient Name',
    scheduleDate: 'Scheduled Date and Time',
    nextRecurrenceDate: 'Next Recurrence Date',
    recurringDates: 'Recurring Dates',
    customized: 'Customized',
    empegoMessage: 'Hello, Thank you for filling out this questionnaire to assist your pharmacist in your consultation: [link]'
  },
  notifications: {
    taskTitle: 'Task assigned',
    taskBody: 'You have a new task assigned',
    messageTitle: 'New messages',
    messageBody: 'You have new unread messages'
  },
  orders: {
    approveBill: 'Approve & Bill',
    chargeCard: 'Charge Card ',
    delivery: 'Delivery',
    deliveries: 'To Fulfill',
    upcomingOrders: 'Fulfilled',
    orderId: 'Order ID',
    orderType: 'Order Type',
    deliveryTime: 'Delivery Time',
    orderPlaced: 'Order Placed',
    fillDate: 'Fill Date',
    fulfillOrder: 'Fulfill Order',
    mail: 'Mail',
    name: 'Name',
    noAddressSelected: 'No Address Selected',
    noPaymentSelected: 'No Payment Selected',
    order: 'Order',
    orderItems: 'Order Items',
    payment: 'Payment',
    pickup: 'Pick up',
    placedOn: 'placed on',
    recentOrders: 'Recent Orders',
    unapprove: 'Unapprove',
    chargeCardTitle: 'Charge Card Order #',
    pleaseSelectPaymentMethod: 'Please select payment method',
    amountTotal: 'Amount Total',
    charge: 'Charge',
    successfullyCharged: 'Successfully Charged',
    errorProcessingPayment: 'There was an error processing the payment. Please try again.',
    errorExpired: 'There was an error with the card: either it`s expired or the address or 3-digit CVV number no longer match.',
    alreadyCharged: 'The Order you have submitted has already been charged.',
    errorDeclined: 'The card was declined due to insufficient funds.',
    total: 'Total',
    subTotal: 'Sub Total',
    printInvoice: 'Print Invoice',
    selectPaymentCard: 'Please select payment card',
    receiptPaymentMethod: 'Payment method',
    receiptPaymentEndingIn: 'ending in',
    receiptPaymentOn: 'on',
    receiptItemQty: 'Item Qty',
    receiptItemName: 'Item Name',
    receiptPrice: 'Price',
    receiptSubTotal: 'Subtotal',
    receiptTotal: 'Total'
  },
  prescription: {
    adherence: 'Adherence',
    areYouSureYouWantToRenewSelectedPrescriptions: 'Are you sure you want to renew selected prescriptions?',
    chronic: 'Chronic',
    classification: 'Classification',
    classifyOptional: 'Classify (optional)',
    dinNameOrIngredient: 'DIN, name or ingredient',
    drug: 'Drug',
    drugRequired: 'Drug Required',
    duration: 'Duration',
    durationBased: 'Duration-based',
    expirationDate: 'Expiration Date',
    fillQty: 'Fill Qty',
    fillDate: 'Fill Date',
    keyboardEnter: 'Enter',
    killRenewal: 'Kill Renewal',
    no: 'No',
    none: 'None',
    notes: 'Notes',
    numberOfRefills: 'Number of Refills',
    numberOfRenewals: 'Number of Renewals',
    originalScriptObtained: 'Original script obtained?',
    pill: 'Pill',
    posology: 'Posology',
    prescribeDate: 'Prescribe Date',
    prescribedDate: 'Prescribed Date',
    prescriber: 'Prescriber',
    prescribeREN: 'Prescribe REN',
    press: 'Press',
    prn: 'PRN',
    quantity: 'Quantity',
    quantityBased: 'Quantity-based',
    refillDurationDays: 'Refill Duration (days)',
    remainingQty: 'REN Remaining (Qty)',
    renew: 'Renew',
    renewal: 'Renewal',
    renewalBased: 'Renewal-based',
    renewedByPharmacy: 'Renewed by pharmacy',
    renewWithCustom: 'Renew with custom quantity',
    active: 'Active',
    inactive: 'Inactive',
    stopped: 'Stopped',
    tablet: 'Tablet',
    toSave: 'to Save',
    totalDuration: 'Total Duration',
    totalQuantity: 'Total Quantity',
    transferIn: 'Transfer in',
    typeOfPrescription: 'Type of Prescription',
    unitsPerRefill: 'Units per Refill',
    unitsPerRefillDuration: 'Units per Refill (Duration)',
    validUntil: 'Valid Until',
    verifiedByPharmacist: 'Verified by pharmacist?'
  },
  prescriptionStoppedReason: {
    title: 'Stopped Reason',
    newPrescription: 'New prescription',
    patient: 'Stopped by patient',
    doctor: 'Stopped by physician',
    pharmacist: 'Stopped by pharmacist'
  },
  predefinedMessage: {
    add: 'Add pre-defined message',
    edit: 'Edit pre-defined message',
    addedSuccessfully: 'Pre-defined message added successfully',
    updatedSuccessfully: 'Pre-defined message updated successfully',
    titleEn: 'Title in English',
    titleFr: 'Title in French',
    messageEn: 'Message in English',
    messageFr: 'Message in French',
    requiredTitle: 'Title is required',
    requiredMessage: 'Message is required'
  },
  priority: {
    high: 'High',
    medium: 'Medium',
    standard: 'Standard'
  },
  professions: {
    doctor: 'Doctor',
    dentist: 'Dentist',
    pharmacist: 'Pharmacist',
    optometrist: 'Optometrist',
    nurse: 'Nurse',
    podiatrist: 'Podiatrist',
    midwife: 'Midwife',
    veterinarian: 'Veterinarian'
  },
  profile: {
    addAddress: 'Add Address',
    addNewAddress: 'Add New Address',
    address1: 'Address 1',
    address2: 'Address 2',
    addressLabel: 'Address Label',
    addRx: 'Add Rx',
    addInsurance: 'Add Insurance',
    addAllergey: 'Add Allergy',
    addPathology: 'Add Pathology',
    addIntolerance: 'Add Intolerance',
    addresses: 'Addresses',
    addressFound: 'address found',
    addressesFound: 'addresses found',
    allergies: 'Allergies',
    apartmentOrUnit: 'Apartment / Unit',
    bulkRenew: 'Bulk Renew',
    deleteAccount: 'Delete Account',
    documents: 'Documents',
    documentFound: 'document found',
    documentsFound: 'documents found',
    editAddress: 'Edit Address',
    editUser: 'Edit User',
    exampleMontreal: 'Ex. Montreal',
    exampleQC: 'Ex. QC',
    insurance: 'Insurance',
    insuranceFound: 'insurance found',
    insurancesFound: 'insurances found',
    intolerance: 'Intolerance',
    label: 'Label',
    logipharmId: 'Logipharm ID',
    logipharmIdMissing: 'Logipharm ID Missing',
    medicalHistory: 'Medical History',
    more: 'More',
    newUser: 'New User',
    no: 'No',
    notVerified: 'Not Verified',
    openChat: 'Open Chat',
    orders: 'Orders',
    orderFound: 'order found',
    ordersFound: 'orders found',
    prescriptions: 'Prescriptions',
    pathologies: 'Pathologies',
    pending: 'Pending',
    photoId: 'Photo ID',
    prescriptionFound: 'prescription found',
    prescriptionsFound: 'prescriptions found',
    ramqMissing: 'RAMQ Missing',
    renewAll: 'Renew All',
    sendPasswordResetForm: 'Send password reset form',
    users: 'Users',
    verified: 'Verified',
    domedicPatientId: 'Domedic Id'
  },
  provinces: {
    alberta: 'Alberta',
    britishColumbia: 'British Columbia',
    manitoba: 'Manitoba',
    newBrunswick: 'New Brunswick',
    newfoundlandAndLabrador: 'Newfoundland and Labrador',
    northwestTerritories: 'Northwest Territories',
    novaScotia: 'Nova Scotia',
    nunavut: 'Nunavut',
    ontario: 'Ontario',
    princeEdwardIsland: 'Prince Edward Island',
    quebec: 'Quebec',
    saskatchewan: 'Saskatchewan',
    yukon: 'Yukon'
  },
  taskPayload: {
    medicalConditions: 'Information médicale',
    allergy: 'Allergies',
    pregnancy: 'Grossesse',
    false: 'Faux',
    allergyOpen: 'TBC what is an example of the return?',
    intolerances: 'Intolérances',
    breastfeeding: 'Allaitement',
    intolerancesOpen: 'TBC what is an example of the return?',
    medicalConditionsOpen: 'TBC what is an example of the return?'
  },
  roles: {
    pharmacist: 'Pharmacist',
    technician: 'Technician',
    bot: 'Bot',
    user: 'Patient',
    nurse: 'Nurse Clinician'
  },
  tasks: {
    action: 'Action',
    address: 'Address',
    addTaskCategory: 'Add Task Category',
    addressAdded: 'Address Added',
    addressDeleted: 'Address Deleted',
    addressId: 'Address ID',
    addressUpdate: 'Address Update',
    allergies: 'Allergies',
    allergy: 'Allergy',
    assignedTo: 'Assigned To',
    attachment: 'Attachment',
    breastfeeding: 'Breastfeeding',
    city: 'City',
    completed: 'Completed',
    cancelled: 'Cancelled',
    createdAt: 'Created at',
    completedAt: 'Completed Date',
    dueDate: 'Due Date',
    createTask: 'Create Task',
    date: 'Date',
    deactivated: 'Deactivated',
    defaultAddress: 'Default Address',
    defaultDelivery: 'Default Delivery',
    defaultMail: 'Default Mail',
    deleteInsurance: 'Delete Insurance',
    description: 'Description',
    done: 'Done',
    doneAll: 'Done All',
    doneTask: 'Done Task',
    editTask: 'Edit Task',
    taskDetails: 'Task details',
    openOrder: 'Open Order',
    filter: 'Filter',
    for: 'For',
    idVerification: 'ID Verification',
    isVerification: 'Is Verification',
    inProgress: 'In Progress',
    toDo: 'To Do',
    idToVerify: 'ID to verify',
    insurance: 'Insurance',
    insuranceName: 'Insurance Name',
    insuranceId: 'Insurance ID',
    intolerances: 'Intolerances',
    label: 'Label',
    medicalConditions: 'Medical Conditions',
    medicalInfo: 'Medical Info',
    newNotification: 'New Notification',
    notificationSent: 'Notification Sent',
    newUser: 'New User',
    notDefault: 'Not Default',
    notVerified: 'Not Verified',
    notes: 'Notes',
    oAuthId: 'oAuthId',
    order: 'Order',
    orderPaid: 'Order Paid',
    paid: 'Paid',
    pending: 'Pending',
    photoId: 'Photo ID',
    postalCode: 'Postal Code',
    pregnancy: 'Pregnancy',
    pregnant: 'Pregnant',
    province: 'Province',
    establishment: 'Establishment',
    establishmentEmployee: 'Establishment Employee',
    phoneNumber: 'Phone number',
    patient: 'Patient',
    priority: 'Priority',
    requiredDescription: 'Description is required',
    requiredAssignedTo: 'Assigned to is required',
    requiredOAuthId: 'OAuthId is required',
    requiredPriority: 'Priority is required',
    requiredTitle: 'Title is required',
    requiredType: 'Type is required',
    rxOrOTC: 'Rx or OTC',
    skipped: 'Skipped',
    streetAddressLineOne: 'Street Address Line One',
    streetAddressLineTwo: 'Street Address Line Two',
    taskId: 'Task ID',
    tasks: 'Tasks',
    taskType: 'Task Type',
    title: 'Title',
    time: 'Time',
    toVerifyByPharmacist: 'To Verify By Pharmacist',
    transfer: 'New Rx (with the photo taken)',
    transfer_prescription: 'New RX (with Photo)',
    transfer_medication: 'Transfer (with photo)',
    transferId: 'Transfer ID',
    type: 'Type',
    updatedAt: 'Updated at',
    user: 'Patient',
    selectDateAndTime: 'Select date and time',
    tooltip: {
      todo: 'Mark as To Do',
      in_progress: 'Mark as In Progress',
      done: 'Mark as Done',
      cancelled: 'Mark as Cancelled',
      edit: 'Edit',
      view: 'View'
    }
  },
  newUser: {
    addNewUser: 'Add New User',
    next: 'Next',
    userAlreadyExist: 'User is already exist on AIRIX',
    linkUser: 'Link user',
    email: 'Email',
    password: 'Password',
    passwordValidation: 'Password must contain at least 8 characters, one lower case, one upper case and one number',
    firstName: 'First Name',
    lastName: 'Last Name',
    birthday: 'Birthday',
    phoneNumber: 'Phone number',
    birthdayPlaceholder: 'DD/MM/YYYY',
    language: 'Language',
    gender: 'Gender',
    empegoCode: 'Empego Code',
    english: 'English',
    french: 'French',
    male: 'Male',
    female: 'Female',
    other: 'Other',
    ramqNumber: 'RAMQ Number',
    updatedAt: 'Updated at',
    user: 'User',
    verified: 'Verified',
    verifiedPhotoId: 'Photo ID Verified',
    website: 'Website',
    withinDeliveryRadius: 'Within Delivery Radius',
    requiredAddress: 'Address is required',
    requiredCity: 'City is required',
    requiredStatus: 'Status is required',
    requiredProvince: 'Province is required',
    requiredFirstName: 'First Name is required',
    requiredLastName: 'Last Name is required',
    requiredLicense: 'License is required',
    requiredName: 'Name is required',
    requiredProfession: 'Profession is required',
    requiredEmail: 'Email is required',
    requiredBirthday: 'Birthday is required',
    requiredPassword: 'Password is required',
    requiredGender: 'Gender is required',
    requiredLanguage: 'Language is required',
    requiredPhoneNumber: 'Phone Number is required',
    requiredRAMQNumber: 'RAMQ Number is required',
    requiredRole: 'Role is required',
    errorEmailInvalid: 'Email Invalid',
    errorPhoneNumberInvalid: 'Invalid Phone number'
  },
  taskCategories: {
    form: {
      description: 'Description',
      descriptionFr: 'Description (French)',
      name: 'Name',
      nameFr: 'Name (French)',
      type: 'Type',
      error: {
        requiredName: 'Name is required',
        requiredNameFr: 'Name (French) is required',
        requiredDescription: 'Description is required',
        requiredDescriptionFr: 'Description (French) is required'
      }
    },
    tooltip: {
      delete: 'Delete this user task category',
      edit: 'Edit this user task category'
    }
  }
}
