export const fr = {
  helloworld: 'Bonjour monde',
  getstarted: 'Débuter',
  common: {
    time: 'Heure',
    actions: 'Actions',
    add: 'Ajouter',
    addNewLink: 'Ajouter un nouveau lien',
    admin: 'Administrateur',
    adminSupport: 'Assistance administrative',
    allUsers: 'Tous les utilisateurs',
    attachFile: 'Pièce jointe',
    cancel: 'Annuler',
    daysInWeek: {
      monday: 'Lundi',
      tuesday: 'Mardi',
      wednesday: 'Mercredi',
      thursday: 'Jeudi',
      friday: 'Vendredi',
      saturday: 'Samedi',
      sunday: 'Dimanche'
    },
    documents: 'Documents',
    yes: 'Oui',
    no: 'Non',
    all: 'Tous',
    easternDaylightTime: 'Heure avancée de l’Est',
    address: 'Adresse',
    addressAddedSuccessfully: 'Adresse ajoutée',
    addressUpdatedSuccessfully: 'Adresse mise à jour',
    approve: 'Approuver',
    approved: 'Approuvé',
    archive: 'Archiver',
    archived: 'Archivé',
    areYouSureYouWantToArchiveThis: 'Êtes-vous sûr de vouloir archiver ceci?',
    areYouSureYouWantToDeleteThis: 'Êtes-vous sûr de vouloir supprimer ceci?',
    areYouSureYouWantToLogout: 'Êtes-vous sûr de vouloir fermer la session?',
    areYouSureYouWantToDeleteIt: 'Êtes-vous sûr de vouloir le supprimer?',
    back: 'Retour',
    bannerLogo: 'Bannière',
    city: 'Ville',
    Clients: 'Clients',
    chronic: 'Chronique',
    clientAddedSuccessfully: 'Client ajouté avec succès ',
    clientDeletedSuccessfully: 'Client supprimé avec succès',
    clientUpdatedSuccessfully: 'Client mis à jour avec succès',
    close: 'Fermer',
    default: 'Par défaut',
    selectAll: 'Sélectionner tout',
    comment: 'Commentaire',
    dateDispensed: 'Date dispensée',
    dateWritten: 'Date écrite',
    drugName: 'Nom du médicament',
    deceased: 'Décédé',
    delete: 'Supprimer',
    delivery: 'Livraison',
    description: 'Description',
    day: 'jour',
    days: 'jours',
    defaultAddress: 'Adresse par défaut',
    docs: 'Documents',
    Drugs: 'Médicaments',
    drugCreated: 'Médicament créé avec succès',
    doctorCreated: 'Prescripteur créé avec succès',
    doctorEdited: 'Prescripteur mis à jour avec succès',
    drugEdited: 'Médicament mis à jour avec succès',
    Doctors: 'Médecins',
    edit: 'Éditer',
    Empego: 'Empego',
    empegoLinks: 'Liens Empego',
    english: 'Anglais',
    false: 'Faux',
    female: 'Femme',
    french: 'Français',
    fulfilled: 'Complété',
    help: 'Aide',
    id: 'Identifiant',
    insuranceAddedSuccessfully: 'Assurance ajoutée',
    insuranceAlreadyApproved: 'Assurance déjà approuvée',
    insuranceProof: "Preuve d'assurance",
    insuranceUpdatedSuccessfully: 'Assurance mise à jour',
    intolerances: 'Intolérances',
    lastSeen: 'Dernière connection',
    link: 'Lier',
    linkedAccounts: 'Comptes liés',
    linkUserTo: "Lier l'utilisateur à",
    linkUserToEstablishmentSuccessfully: "Utilisateur lié à l'établissement avec succès",
    loading: 'Chargement en cours ...',
    locale: 'fr',
    logOut: 'Se déconnecter',
    mail: 'Poste',
    male: 'Homme',
    name: 'Nom',
    medicalForm: 'Formulaire médical',
    medicalInfo: 'Information médicale',
    Messages: 'Messages',
    morning: 'matin',
    neverSignedIn: "ne s'est jamais connecté",
    noFound: 'Aucune trouvée',
    none: 'Aucune',
    notAvailable: 'Non disponible',
    notVerified: 'Non vérifié',
    notServed: 'Non rempli',
    noOptions: 'Aucune option',
    noResultFound: 'Aucun résultat trouvé',
    noUserLinked: 'Aucun utilisateur lié',
    oAuthIdOfUserAccountToLink: 'oAuthId du compte utilisateur à lier',
    ok: 'OK',
    optional: 'Facultatif',
    orderApprovedSuccessfully: 'Commande approuvée',
    orderBy: 'Tier par',
    orderChargedSuccessfully: 'Commande facturée',
    orderUpdatedSuccessfully: 'Commande mise à jour',
    orderDeletedSuccessfully: 'Commande supprimée avec succès',
    other: 'Autre',
    paid: 'Payé',
    Establishments: 'Établissements',
    pharmacist: 'Pharmacienne',
    establishmentSwitchedTo: "L'établissement est passée à",
    picture: 'Image',
    establishmentPhoneNumber: "Numéro de téléphone de l'établissement",
    photoAlreadyVerified: 'Photo déjà vérifée',
    photoIdNotProvided: 'Identifiant de photo non fourni',
    predefinedMessages: 'Messages prédéfinis',
    prescriptionCreatedSuccessfully: 'Ordonnance créée',
    prescriptionUpdatedSuccessfully: 'Ordonnance mise à jour',
    price: 'Prix',
    postalCode: 'Code postal',
    quantity: 'Quantité',
    reactivate: 'Reactiver',
    reject: 'Rejeter',
    repeatEvery: 'Répétez tous les',
    after: 'Après',
    week: 'Semaine',
    month: 'Mois',
    daily: 'Jours',
    ends: 'Prend fin',
    on: 'le',
    occurrences: 'Occurrences',
    rxRenewedSuccessfully: 'Rx renouvelé',
    save: 'Sauvegarder',
    scheduleSend: "Planifier l'envoi",
    search: 'Rechercher',
    searchAFile: 'Rechercher un fichier',
    send: 'Envoyer',
    select: 'Sélectionner',
    manageReccurence: 'Gérer la récurrence',
    scheduleButton: 'Planifier',
    selectOption: 'Sélectionner une option',
    selectOptions: 'Sélectionnez les options',
    shipped: 'Expédié',
    status: 'Statut',
    superAdmin: 'Super Administrateur',
    'super admin': 'Super Administrateur',
    switchLanguage: 'Switch to English',
    taskCreatedSuccessfully: 'Tâche créée avec succès',
    taskUpdatedSuccessfully: 'Tâche mise à jour avec succès',
    taskCompletedSuccessfully: 'Tâche complétée',
    taskCategoryCreated: 'Catégorie de tâche créée avec succès',
    taskCategoryDeleted: 'Catégorie de tâches supprimée avec succès',
    taskCategoryUpdated: 'Catégorie de tâche mise à jour avec succès',
    task: 'Tâche',
    Tasks: 'Tâches',
    taskCategories: 'Catégories de tâches',
    true: 'Vrai',
    type: 'Type',
    unauthorizedAccess: 'Accès non autorisé',
    Orders: 'Commandes',
    Followups: 'Suivi',
    unapproved: 'Non approuvé',
    unlink: 'Dissocier',
    unlinkUserFromEstablishmentSuccessfully: "L'utilisateur a été dissocié de l'établissement avec succès",
    Users: 'Utilisateurs',
    userId: 'Identifiant utilisateur ',
    userIdCopied: 'Identifiant d`utilisateur copié',
    userLinkedSuccessfully: 'Utilisateur lié',
    userUpdatedSuccessfully: 'Utilisateur mis à jour',
    userUnlinkedSuccessfully: 'Utilisateur dissocié',
    verbalScript: 'Verbale',
    verified: 'Verifié',
    verifyPhotoId: 'Vérifier identifiant de photo',
    writtenScript: 'Écrite',
    Agenda: 'Ordre du jour',
    Shipping: 'Expédition',
    drugAdded: 'Médicament ajouté avec succès',
    userAlreadyExistInCurrentEstablishment: "L`utilisateur existe déjà dans l'établissement actuel",
    failToCreateANewUser: 'Échec de la création d`un nouvel utilisateur',
    userCreated: 'Utilisateur créé',
    userLinked: 'Utilisateur lié',
    failDeleteInsurance: 'Échec de la suppression de l`assurance',
    pathologyNameEn: 'Nom de la pathologie (anglais)',
    pathologyNameFr: 'Nom de la pathologie (français)',
    requiredPathologyNameEn: 'Le nom de la pathologie est requis (anglais)',
    requiredPathologyNameFr: 'Le nom de la pathologie est requis (français)',
    requiredPreCodeLink: 'Un lien de pré-code est requis',
    requiredPostCodeLink: 'Un lien vers le code postal est requis',
    failDeleteScheduledMessage: 'Échec de la suppression du message programmé'
  },
  clients: {
    addClient: 'Ajouter un client',
    addEstablishment: 'Ajouter un établissement',
    addAUser: 'Ajouter un utilisateur',
    bannerName: 'Nom de la bannière',
    businessHours: 'Heures de travail',
    manageUsers: 'Gérer les utilisateurs',
    modifyClient: 'Modifier Établissement',
    modify: 'Modifier',
    delete: 'Supprimer',
    zipCode: 'Code postal',
    phoneNumber: 'Téléphone',
    name: 'Nom',
    postalCode: 'Code postal',
    phone: 'Téléphone'
  },
  doctors: {
    license: 'Licence',
    note: 'Note',
    streetAddress: 'Adresse de la rue',
    firstName: 'Nom',
    lastName: 'Nom de famille',
    profession: 'Profession',
    phoneNumber: 'Numéro de téléphone',
    faxNumber: 'Numéro de fax',
    searchPrescribers: 'Rechercher des prescripteurs',
    createPrescriber: 'Créer un prescripteur',
    editPrescriber: 'Modifier le prescripteur'
  },
  documents: {
    attach: 'Attacher',
    addFileTo: 'Ajouter un fichier à',
    browseFiles: 'Faites glisser les fichiers ici ou cliquez pour parcourir',
    documentsAddedSuccessfully: 'Document ajouté avec succès',
    documentRemovedSuccessfully: 'Document supprimé avec succès',
    insuranceProof: "Preuves d'assurance",
    fileAddedTo: 'Fichier ajouté avec succès à',
    fileName: 'Nom de fichier',
    medicalForm: 'Formulaires médicaux',
    medicalInformation: 'Informations médicales',
    noDocumentFound: 'Aucun document trouvé',
    picture: 'Images',
    prescription: 'Ordonnances',
    selectACategoryForUpload: 'Sélectionnez une catégorie à télécharger',
    maxSize: 'Max. taille du fichier',
    uploading: 'Téléchargement',
    fileSizeExceeds: 'La taille du fichier dépasse',
    upload: 'Télécharger',
    uploadFromDevice: "Télécharger depuis l'appareil",
    uploadFromDocuments: 'Télécharger à partir de documents',
    selectMultiple: 'Sélectionnez plusieurs',
    noDocuments: 'Aucun document',
    types: {
      photo: 'Photo',
      prescription: 'Préscription',
      'medical form': 'Formulaire médical',
      'proof of insurance': "Preuve d'assurance",
      'medication sheet': 'Feuillet médicaments',
      'medication information sheet': "Feuillet d'information médicale",
      'user manual': "Mode d'emploi",
      other: 'Autre'
    }
  },
  drugs: {
    drugId: 'Identifiant du médicament',
    drugIdentificationNumber: 'DIN/NPN',
    name: 'Nom',
    genericInfo: 'Informations sur la génération',
    legalStatusQc: 'Statut juridique du QC',
    form: 'Formulaire',
    strength: 'Force',
    createDrug: 'Créer un médicament',
    searchDrugs: 'Rechercher des médicaments',
    editDrug: 'Modifier le médicament',
    nameEnCapitalized: 'Nom du médicament en anglais (obligatoire)',
    nameFrCapitalized: 'Nom du médicament en Francais',
    strengthEnLowercase: 'Force en anglais (obligatoire)',
    strengthFrLowercase: 'Force en français',
    formEnLowercase: 'Formulaire en anglais',
    formFrLowercase: 'Formulaire en français',
    dinOrNpn: "DIN ou NPN (vérifiez pour vous assurer qu'il n'est pas déjà dans la base de données)",
    dinOrNpnText: 'DIN/NPN',
    defaultPosology: 'Posologie par défaut',
    requiredDrugName: 'Le nom du médicament est requis',
    requiredStrength: 'Force requise',
    requiredDinOrNpn: 'IN/NPN est requis',
    requiredFormInEnglish: 'Le formulaire est requis',
    requiredFormInFrench: 'Le formulaire est requis',
    requiredDefaultPosology: 'Une posologie par défaut est requise',
    videoUrl: 'Lien vidéo'
  },
  editUser: {
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    ramqNumber: 'Numéro RAMQ',
    logipharmId: 'Identifiant logipharm',
    paysafeId: 'Identifiant paysafe',
    email: 'Courriel',
    website: 'Site Web',
    birthday: 'Date de naissance',
    phoneNumber: 'Numéro de téléphone',
    gender: 'Genre',
    language: 'Langue',
    domedicPatientId: 'Identifiant domedic',
    addEstablishments: 'Ajouter des établissements',
    addPharmacies: 'Ajouter des pharmacies',
    role: 'Rôle',
    pinConversation: 'Épingler la conversation'
  },
  empegoLink: {
    empegoLinkAdded: 'Lien Empego ajouté avec succès',
    empegoLinkUpdated: 'Lien Empego mis à jour avec succès',
    empegoLinkDeleted: 'Lien Empego supprimé avec succès',
    fullLink: 'Lien complet',
    preCodeLink: 'Partie statique du lien',
    postCodeLink: 'Partie dynamique du lien',
    defaultLink: 'Lien par défaut'
  },
  insurance: {
    addInsurance: 'Ajouter une assurance',
    areYouSureYouWantTo: 'Êtes-vous sûr de vouloir',
    areYouSureYouWantToDeleteThis: 'Êtes-vous sûr de vouloir supprimer ceci?',
    editInsurance: 'Modifier une assurance',
    insuranceName: "Nom de l'assurance",
    fields: 'Des champs',
    priority: 'Priorité',
    photos: 'Photos',
    thisInterogation: 'ceci?',
    verified: 'Vérifié'
  },
  medicalHistory: {
    allergy: 'allergie',
    allergiesFound: 'allergies trouvées',
    allergyFound: 'allergie trouvée',
    areYouSureYouWantToDelete: 'Êtes-vous sûr de vouloir supprimer?',
    intolerance: 'intolérance',
    intoleranceFound: 'intolérance trouvée',
    intolerancesFound: 'intolérances trouvées',
    pathology: 'pathologie',
    pathologiesFound: 'pathologies trouvées',
    pathologyFound: 'pathologie trouvée',
    no: 'Aucune',
    notePart1a: 'Note: ajoutez la',
    notePart1an: "Note: ajoutez l'",
    notePart2: "dans la langue de l'utilisateur pour que l'affichage côté utilisateur soit correct"
  },
  message: {
    afterTwoDays: 'Après 2 jours',
    afterSevenDays: 'Après 7 jours',
    afterThirtyDays: 'Après 30 jours',
    enterMessage: 'Entrer un message',
    message: 'un message',
    openProfile: 'Ouvrir le profil',
    searchMessage: 'Rechercher message',
    searchUser: 'Rechercher utilisateur',
    sendAsAdmin: "Envoyer en tant qu'administrateur",
    sendAsBot: 'Envoyer en tant que bot',
    tomorrowMorning: 'Demain matin',
    tomorrowAfternoon: 'Demain soir',
    tomorrowEvening: 'Tomorrow evening',
    pickDateAndTime: "Choisissez la date et l'heure",
    customRecurrence: 'Récurrence personnalisée',
    scheduledSuccessfully: 'Message planifié avec succès',
    editScheduledSuccessfully: 'Message modifié avec succès',
    editScheduledMessage: 'Modifier le message programmé',
    messageText: 'Texte du message',
    commonMessages: 'Messages courants',
    scheduledPosts: 'Messages planifiés',
    recipientName: 'Nom du destinataire',
    scheduleDate: 'Date et heure prévue',
    nextRecurrenceDate: 'Prochaine date de récurrence',
    recurringDates: 'Dates récurrentes',
    customized: 'Personnalisé',
    empegoMessage: 'Bonjour, Merci de remplir ce questionnaire pour assister votre pharmacien(ne) dans votre consultation : [lien]'
  },
  notifications: {
    taskTitle: 'Tâche assignée',
    taskBody: 'Vous avez une nouvelle tâche assignée',
    messageTitle: 'Nouveaux messages',
    messageBody: 'Vous avez de nouveaux messages non lus'
  },
  orders: {
    approveBill: 'Approuver et facturer',
    chargeCard: 'Charger la carte',
    delivery: 'Livraison',
    deliveries: 'À Remplir',
    upcomingOrders: 'Remplis',
    orderId: 'numéro de commande',
    orderType: 'Type de commande',
    orderPlaced: 'Commande passée',
    deliveryTime: 'Date de livraison',
    fillDate: 'Date de remplissage',
    fulfillOrder: 'Remplir la commande',
    mail: 'Colis',
    name: 'Nom',
    noAddressSelected: 'Aucune adresse selectionnée',
    noPaymentSelected: 'Aucun paiement sélectionné',
    order: 'Commande',
    orderItems: 'Articles commandés',
    payment: 'Paiement',
    pickup: 'Cueillette',
    placedOn: 'pour le',
    recentOrders: 'Commandes récentes',
    unapprove: 'Rejeter',
    chargeCardTitle: 'Appliquer la charge sur la commande #',
    pleaseSelectPaymentMethod: 'Veuillez sélectionner une méthode de paiement',
    amountTotal: 'Montant total',
    charge: 'Facturer',
    successfullyCharged: 'Facturé avec succès',
    errorProcessingPayment: 'Il y a eu une erreur lors du traitement du paiement. Veuillez essayer à nouveau dans quelques minutes',
    errorExpired: 'Il y a eu une erreur avec la carte: soit elle est expirée, sinon l`adresse ou le numéro CVV à trois chiffres n`est plus valide.',
    alreadyCharged: 'La commande que vous avez soumise a déjà été débitée.',
    errorDeclined: 'La carte a été déclinée due à des fonds insuffisants.',
    total: 'Total',
    subTotal: 'Sous-total',
    printInvoice: 'Imprimer la facture',
    selectPaymentCard: 'Veuillez sélectionner une carte de paiement',
    receiptPaymentMethod: 'Méthode de paiement',
    receiptPaymentEndingIn: 'finissant par',
    receiptPaymentOn: 'le',
    receiptItemQty: 'Qté',
    receiptItemName: 'Nom d`article',
    receiptPrice: 'Montant',
    receiptSubTotal: 'Sous-total',
    receiptTotal: 'Total'
  },
  prescription: {
    adherence: 'Adhérence',
    areYouSureYouWantToRenewSelectedPrescriptions: 'Êtes-vous sûr de vouloir renouveler les ordonnances sélectionnées?',
    chronic: 'Chronique',
    classification: 'Classification',
    classifyOptional: 'Classifier (optionel)',
    dinNameOrIngredient: 'DIN, nom ou ingrédient',
    drug: 'Médicament',
    drugRequired: 'Médicament requis',
    duration: 'Durée',
    durationBased: 'Basée sur durée',
    expirationDate: "Date d'expiration",
    fillQty: 'Quantité de remplissage',
    fillDate: 'Date de remplissage',
    keyboardEnter: 'Entrée',
    killRenewal: 'Supprimer le renouvellement',
    no: 'Aucune ',
    none: 'Aucune',
    notes: 'Notes',
    numberOfRefills: 'Nombre de remplissages',
    numberOfRenewals: 'Nombre de renouvellements',
    originalScriptObtained: 'Script original obtenu?',
    pill: 'Pilule',
    posology: 'Posologie',
    prescribeDate: "Date de l'ordonnance",
    prescribedDate: "Date de l'ordonnance",
    prescriber: 'Prescripteur',
    prescribeREN: 'Prescrire REN',
    press: 'Appuyez sur',
    prn: 'PRN',
    quantity: 'Quantité',
    some: 'SOME',
    quantityBased: 'Basée sur quantité',
    refillDurationDays: 'Durée de remplissage (jours)',
    remainingQty: 'REN restant (qté)',
    renew: 'Renouveler',
    renewal: 'Renouvellement',
    renewalBased: 'Basée sur renouvellement',
    renewedByPharmacy: 'Renouvelée par la pharmacie',
    renewWithCustom: 'Renouveler avec quantité personnalisée',
    stopped: 'Arrêté',
    active: 'Actif',
    inactive: 'Inactif',
    tablet: 'Comprimé',
    toSave: 'pour sauvegarder',
    totalDuration: 'Durée totale',
    totalQuantity: 'quantité totale',
    transferIn: 'Transféré ici',
    typeOfPrescription: "Type d'ordonnance",
    unitsPerRefill: 'Unités par remplissage',
    unitsPerRefillDuration: 'Unités par remplissage (durée)',
    validUntil: "Valide jusqu'à",
    verifiedByPharmacist: 'Vérifié par un pharmacien?'
  },
  prescriptionStoppedReason: {
    title: "Raison d'arrêt",
    newPrescription: 'Nouvelle ordonnance',
    patient: 'Cessé par patient',
    doctor: 'Cessé par médecin',
    pharmacist: 'Cessé par pharmacien'
  },
  predefinedMessage: {
    add: 'Ajouter un message prédéfini',
    edit: 'Modifier un message prédéfini',
    addedSuccessfully: 'Message prédéfini ajouté avec succès',
    updatedSuccessfully: 'Message prédéfini mis à jour avec succès',
    titleEn: 'Titre en anglais',
    titleFr: 'Titre en français',
    messageEn: 'Message en anglais',
    messageFr: 'Message en français',
    requiredTitle: 'Le titre est requis',
    requiredMessage: 'Le message est requis'
  },
  priority: {
    high: 'Haut',
    medium: 'Moyen',
    standard: 'Standard'
  },
  professions: {
    doctor: 'Médecin',
    dentist: 'Dentiste',
    pharmacist: 'Pharmacien',
    optometrist: 'Optométriste',
    nurse: 'Infirmier',
    podiatrist: 'Podologue',
    midwife: 'Sage-femme',
    veterinarian: 'Vétérinaire'
  },
  profile: {
    addAddress: 'Ajouter une adresse',
    addNewAddress: 'Ajouter une nouvelle adresse',
    addRx: 'Ajouter Rx',
    address1: 'Adresse 1',
    address2: 'Adresse 2',
    addressLabel: "Étiquette de l'adresse",
    addInsurance: 'Ajouter une assurance',
    addAllergey: 'Ajouter une allergie',
    addPathology: 'Ajouter une pathologie',
    addIntolerance: 'Ajouter une intolérance',
    addressFound: 'adresse trouvée',
    addressesFound: 'adresses trouvées',
    addresses: 'Adresses',
    allergies: 'Allergies',
    apartmentOrUnit: 'Appartement / Unité',
    bulkRenew: 'Renouveler en vrac',
    deleteAccount: 'Supprimer le compte',
    documents: 'Documents',
    documentFound: 'Document trouvé',
    documentsFound: 'Documents trouvés',
    editAddress: 'Modifier une adresse',
    editUser: "Modifier l'utilisateur",
    exampleMontreal: 'Ex. Montréal',
    exampleQC: 'Ex. QC',
    insurance: 'Assurance',
    insuranceFound: 'assurance trouvée',
    insurancesFound: 'assurances trouvées',
    intolerance: 'Intolérance',
    label: 'Titre',
    logipharmId: 'Identifiant Logipharm',
    logipharmIdMissing: 'N.B. Afficher que si pharmacie avec Logiparm',
    medicalHistory: 'Antécédents médicaux',
    more: 'Autres',
    newUser: 'Nouvel utilisateur',
    no: 'Aucune',
    notVerified: 'Non vérifié',
    openChat: 'Clavarder',
    orders: 'Commandes',
    orderFound: 'commande trouvée',
    ordersFound: 'commandes trouvées',
    prescriptions: 'Ordonnances',
    pathologies: 'Pathologies',
    pending: 'En attente',
    photoId: 'Identifiant de photo',
    prescriptionFound: 'ordonnance trouvée',
    prescriptionsFound: 'ordonnances trouvées',
    ramqMissing: 'RAMQ Manquant',
    renewAll: 'Tout renouveler',
    sendPasswordResetForm: 'Envoyer la récupération de mot de passe',
    users: 'Utilisateurs',
    verified: 'Vérifié',
    domedicPatientId: 'Identifiant Domedic'
  },
  provinces: {
    alberta: 'Alberta',
    britishColumbia: 'Colombie-Britannique',
    manitoba: 'Manitoba',
    newBrunswick: 'Nouveau-Brunswick',
    newfoundlandAndLabrador: 'Terre-Neuve-et-Labrador',
    northwestTerritories: 'Territoires du Nord-Ouest',
    novaScotia: 'Nouvelle-Écosse',
    nunavut: 'Nunavut',
    ontario: 'Ontario',
    princeEdwardIsland: 'Île-du-Prince-Édouard',
    quebec: 'Québec',
    saskatchewan: 'Saskatchewan',
    yukon: 'Yukon'
  },
  roles: {
    pharmacist: 'Pharmacienne',
    technician: 'Technicienne',
    bot: 'Robot',
    user: 'Utilisateur',
    nurse: 'Infirmière clinicienne'
  },
  tasks: {
    action: 'Action',
    address: 'Adresse',
    addTaskCategory: 'Ajouter une catégorie de tâches',
    addressAdded: 'Adresse ajoutée',
    addressDeleted: 'Adresse supprimée',
    addressId: "Identifiant d'adresse",
    addressUpdate: 'Adresse mise à jour',
    allergies: 'Allergies',
    allergy: 'Allergie',
    assignedTo: 'Assigné à',
    attachment: 'Pièce jointe',
    breastfeeding: 'Allaite',
    city: 'Ville',
    completed: 'Complété',
    cancelled: 'Annulé',
    completedAt: 'Date de fin',
    createdAt: 'Créée le',
    createTask: 'Créer une tâche',
    date: 'Date',
    dueDate: "Date d'échéance",
    deactivated: 'Désactivée',
    defaultAddress: 'Adresse par défaut',
    defaultDelivery: 'Livraison par défaut',
    defaultMail: 'Poste par défaut',
    deleteInsurance: 'Assurance supprimée',
    description: 'Description',
    done: 'Compléter',
    doneAll: 'Tout compléter',
    doneTask: 'Tâche complétée',
    editTask: 'Modifier la tâche',
    taskDetails: 'Détails de la tâche',
    openOrder: 'Ouvrir la commande',
    filter: 'Filtre',
    for: 'Pour',
    idVerification: "Vérification d'identifiant",
    isVerification: 'La vérification est-elle',
    idToVerify: "Pièce d'identité à vérifier",
    insurance: 'Assurance',
    inProgress: 'En cours',
    toDo: 'À faire',
    insuranceName: "Nom de l'assurance",
    insuranceId: "Identifiant d'assurance",
    intolerances: 'Intolérances',
    label: 'Étiquette',
    medicalConditions: 'Conditions médicales',
    medicalInfo: 'Information médicale',
    newNotification: 'Nouvelle notification',
    notificationSent: 'Notification envoyée',
    newUser: 'Nouvel utilisateur',
    notDefault: 'Non',
    notVerified: 'Non vérifié',
    notes: 'Remarques',
    oAuthId: 'oAuthId',
    order: 'Commande',
    orderPaid: 'Commande payée',
    patient: 'Patient',
    paid: 'Payé',
    pending: 'En attente',
    establishmentEmployee: "Employé de l'établissement",
    photoId: 'Identifiant de photo',
    postalCode: 'Code postal',
    pregnancy: 'Grocesse',
    pregnant: 'Enceinte',
    province: 'Province',
    establishment: 'Établissement',
    phoneNumber: 'Numéro de téléphone',
    priority: 'Priorité',
    requiredAssignedTo: 'Attribué à est requis',
    requiredDescription: 'Une description est requise',
    requiredOAuthId: 'OAuthId est requis',
    requiredPriority: 'La priorité est requise',
    requiredTitle: 'Le titre est requis',
    requiredType: 'Le type est requis',
    rxOrOTC: 'Rx ou OTC',
    skipped: 'Ignoré',
    streetAddressLineOne: 'Adresse ligne 1',
    streetAddressLineTwo: 'Adresse ligne 2',
    taskId: 'Identifiant de tâche',
    tasks: 'Tâches',
    taskType: 'Type de tâche',
    title: 'Titre',
    time: 'Temps',
    toVerifyByPharmacist: 'À vérifier par pharmacien',
    transfer: 'Nouveau Rx (avec photo prise)',
    transfer_prescription: 'Nouveau RX (avec photo)',
    transfer_medication: 'Transfert (avec photo)',
    transferId: 'Identifiant de transfert',
    type: 'Type',
    user: 'Utilisateur',
    updatedAt: 'Mis à jour le',
    selectDateAndTime: "Sélectionnez la date et l'heure",
    tooltip: {
      todo: 'Marquer comme à faire',
      in_progress: 'Marquer comme en cours',
      done: 'Marquer comme terminé',
      cancelled: 'Marquer comme annulé',
      edit: 'Modifier',
      view: 'Voir'
    }
  },
  newUser: {
    addNewUser: 'Ajouter un nouvel utilisateur',
    next: 'Suivant',
    userAlreadyExist: "L'utilisateur existe déjà sur AIRIX",
    linkUser: "Lier l'utilisateur",
    email: 'Courriel',
    empegoCode: 'Code Empego',
    password: 'Mot de passe',
    passwordValidation: 'Le mot de passe doit contenir au minimum 8 caractères, une lettre minuscule, une lettre majuscule et un chiffre',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    birthday: 'Date de naissance',
    phoneNumber: 'Numéro de téléphone',
    birthdayPlaceholder: 'JJ/MM/AAAA',
    language: 'Langue',
    gender: 'genre',
    english: 'Anglais',
    french: 'Français',
    male: 'Homme',
    female: 'Femme',
    other: 'Autre',
    ramqNumber: 'Numéro RAMQ',
    updatedAt: 'Mis à jour le',
    user: 'Utilisateur',
    verified: 'Vérifié',
    verifiedPhotoId: 'Identifiant de photo vérifié',
    website: 'Site Web',
    withinDeliveryRadius: 'Dans le rayon de livraison',
    requiredAddress: "L'adresse est requise",
    requiredCity: 'La ville est obligatoire',
    requiredStatus: 'Le statut est requis',
    requiredProvince: 'La province est requise',
    requiredFirstName: 'Prénom est requis',
    requiredLastName: 'Nom de famille est requis',
    requiredLicense: 'Une licence est requise',
    requiredProfession: 'Une profession est requise',
    requiredEmail: 'Courriel est requis',
    requiredBirthday: 'Date de naissance est requise',
    requiredName: 'Le nom est requis',
    requiredPassword: 'Mot de passe est requis',
    requiredGender: 'Genre est requis',
    requiredLanguage: 'Langue est requise',
    requiredPhoneNumber: 'Numéro de téléphone est requis',
    requiredRAMQNumber: 'Le numéro RAMQ est requis',
    requiredRole: 'Le rôle est requis',
    errorEmailInvalid: 'Courriel invalid',
    errorPhoneNumberInvalid: 'Numéro de téléphone invalid'
  },
  taskCategories: {
    form: {
      description: 'Description (anglais)',
      descriptionFr: 'Description (français)',
      name: 'Nom (anglais)',
      nameFr: 'Nom (français)',
      type: 'Type',
      error: {
        requiredName: 'Le nom anglais est requis',
        requiredNameFr: 'Le nom français est requis',
        requiredDescription: 'La description anglaise est requise',
        requiredDescriptionFr: 'La description française est requise'
      }
    },
    tooltip: {
      delete: 'Supprimer ce categorie de tâche',
      edit: 'Modifier ce categorie de tâche'
    }
  }
}
